import React from "react"

import Band from "../../components/band"
import Layout from "../../components/layout"

import miLetter from "../../assets/files/CareFirst THP Substitute Notice.pdf"

const LetterMI = () => (
  <Layout header="Didn’t Receive a Letter Regarding the Cyberattack?" isMi={true}>
    <Band>
      <p>
        The information for members in the Harbor Health Medicare Advantage or
        Exchange plans or enrolled in the Trusted Health Plan Michigan, Inc. Medicaid plan may
        have been impacted by the event. The Harbor Health Plan was located in Michigan.
      </p>
      <p>
        If you didn’t receive a notification letter and you were an enrollee in a Harbor
        Health Plan  or Trusted Health Plan Michigan, please <a href={miLetter} target="_blank" rel="noreferrer">click here</a>.
      </p>
    </Band>
  </Layout>
)

export default LetterMI
